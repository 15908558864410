import { Events, EventBus } from '@/events';
import { initColumnsConfig } from 'components/main-table/configs/fields';
import accessLevelsConfig from '@/configs/accessConfig.json';
import { redirectIfExpired, redirectIfRestricted, checkRestrictedUser } from '@/helpers/service';
import apiFactory from '@/api';
import { cookieSetters } from '@/helpers/cookieManager';
import { PROTOCOL_REGEXP } from '@/constants';
import i18n from '@/i18n';
import toastr from '@/helpers/init-toastr';
import { replay } from '@/services/sentry'

const usersApi = apiFactory.get('users');
const userApi = apiFactory.get('user');

import {
    SET_IS_NOT_STRIPE_USER,
    SET_PAYMENT_DETAILS_LOADED,
    SET_CURRENT_PLAN,
    SET_DEFAULT_CARD,
    SET_FILTER_BY_TAG,
    SET_KEYWORDS_TABLE_SETTINGS,
    SET_PROJECTS_SEARCH,
    SET_SETTINGS_IS_FETCHING,
    SET_USER_SETTINGS,
    SET_SUBSCRIPTION_DATA,
    SET_PERMISSIONS_DATA,
    SET_PERMISSIONS_DATA_LOADED,
    SET_USED_REFRESHES,
    SET_USED,
    SET_USED_IS_FETCHING,
    SET_USER_DATA_PROP,
    SET_USER_DATA,
    SET_DAILY_ADDED,
    SET_IS_FREE_PLAN,
    SET_IS_AUTH_USER,
    SET_EMAIL_NOTIFICATION_SETTINGS_DATA,
    SET_EMAIL_NOTIFICATION_SETTINGS_PROP,
    SET_IS_RESTRICTED_ACCESS_USER,
} from '@/store/mutations';

import {
    prepareDisplaySettings,
    prepareColumnsAndOtherSettings,
} from '@/store/utils/settings';

const accessLevels = accessLevelsConfig;
const defaultUserData = {
    accessLevel: accessLevels.MASTER_ACCESS,
    gscConnected: false,
    addonMasterEmail: '',
    addonMasterDisabled: false,
    createdAt: '',
    email: '',
    lastLoginAt: '',
    package: 0,
    refreshLimit: 0,
    refreshRemaining: 0,
    sharedCountApi: '',
    createdAtTs: '',
    intercomHash: '',
    intercomUserId: '',
    userId: '',
    disabled: false,
    trialIsUsed: false,
    used: 0
};

const settings = {
    state: {
        used: 0,
        usedIsFetching: false,
        isNotStripeUser: false,
        paymentDetailsLoaded: false,
        refreshed: 0,
        dailyAdded: {
            limit: 0,
            used: 0
        },
        userData: defaultUserData,
        subscriptionData: {
            packageName: '',
            packageLimit: '',
            billingCycle: '',
            cycle: '',
            active: 0,
            currentPay: 0,
            nextPay: 0,
            stripe_plan_amount: 0,
        },
        permissionsData: {
            on_demand_updates: false,
            mobile_tracking: false,
            share_of_voice: false,
            api_access: false,
            custom_alerts: true,
            custom_viewkey_domain: true,
            customer_support: true,
            dashthis_integration: true,
            google_integrations: true,
            local_rank_tracking: true,
            scheduled_reports: true,
            search_intent: true,
            serp_features: true,
            serp_history: true,
            spyglass: true,
            suggestions: true,
            unlimited_add_ons: true,
            unlimited_domains: true,
            viewkey_reports: true,
        },
        permissionsDataLoaded: false,
        userSettings: {
            invoicedetails: '',
            timezone: '',
            projectsSearch: '',
            filterByTags: null,
            newsletter_subscription: false,
        },
        settingsIsFetching: true,
        isFreePlan: false,
        isAuthUser: false,
        notificationSettings: {
            isFetched: false,
            performance_pulse_email_enabled: true,
            portfolio_snapshot_email_enabled: false,
            project_list: []
        },
        isRestrictedAccessUser: checkRestrictedUser(),
    },
    mutations: {
        [SET_USED]: (state, payload) => state.used = payload,
        [SET_USED_IS_FETCHING]: (state, payload) => state.usedIsFetching = payload,
        [SET_USED_REFRESHES]: (state, payload) => state.refreshed = payload,
        [SET_SETTINGS_IS_FETCHING]: (state, payload) => state.settingsIsFetching = payload,
        [SET_USER_DATA]: (state, payload) => state.userData = payload,
        [SET_SUBSCRIPTION_DATA]: (state, payload) => state.subscriptionData = payload,
        [SET_PERMISSIONS_DATA]: (state, payload) => state.permissionsData = payload,
        [SET_PERMISSIONS_DATA_LOADED]: (state, payload) => state.permissionsDataLoaded = payload,
        [SET_PROJECTS_SEARCH]: (state, payload) => state.userSettings = payload,
        [SET_FILTER_BY_TAG]: (state, filterByTags) => state.userSettings.filterByTags = state.userSettings.filterByTags ? null : filterByTags,
        [SET_USER_DATA_PROP]: (state, { prop, value }) => state.userData[prop] = value,
        [SET_USER_SETTINGS]: (state, payload) => state.userSettings = payload,
        [SET_IS_NOT_STRIPE_USER]: (state, payload) => state.isNotStripeUser = payload,
        [SET_PAYMENT_DETAILS_LOADED]: (state, payload) => state.paymentDetailsLoaded = payload,
        [SET_DAILY_ADDED]: (state, payload) => state.dailyAdded = payload,
        [SET_IS_FREE_PLAN]: (state, payload) => state.isFreePlan = payload,
        [SET_IS_AUTH_USER]: (state, payload) => state.isAuthUser = payload,
        [SET_EMAIL_NOTIFICATION_SETTINGS_DATA]: (state, payload) => state.notificationSettings = payload,
        [SET_EMAIL_NOTIFICATION_SETTINGS_PROP]: (state, { prop, value }) => state.notificationSettings[prop] = value,
        [SET_IS_RESTRICTED_ACCESS_USER]: (state, payload) => state.isRestrictedAccessUser = payload,
    },
    getters: {
        getIsNotStripeUser: state => state.isNotStripeUser,
        getPaymentDetailsLoaded: state => state.paymentDetailsLoaded,
        getRefreshed: state => state.refreshed,
        getSettingIsFetching: state => state.settingsIsFetching,
        getUserDataProp: state => prop => state.userData[prop],
        getUserSetting: state => prop => state.userSettings[prop],
        getUserSettings: state => state.userSettings,
        getSubscriptionData: state => state.subscriptionData,
        getPermissionsData: state => state.permissionsData,
        getPermissionsDataLoaded: state => state.permissionsDataLoaded,
        getPermissionData: state => prop => state.permissionsData[prop],
        getUserData: state => state.userData,
        isViewOnlyUser: state => state.userData.accessLevel === accessLevels.VIEW_ACCESS,
        isAddonUser: state => state.userData.accessLevel === accessLevels.ADDON_ACCESS,
        isMasterUser: state => state.userData.accessLevel === accessLevels.MASTER_ACCESS,
        isAdminUser: state => state.userData.accessLevel === accessLevels.ADMIN_ACCESS,
        isAdminOrMasterUser: state => state.userData.accessLevel === accessLevels.MASTER_ACCESS
            || state.userData.accessLevel === accessLevels.ADMIN_ACCESS,
        isAddonRestricted: state => state.userData.addonRestricted,
        getUsedKeywords: state => state.used,
        getUsedKeywordsIsFetching: state => state.usedIsFetching,
        getPackageKeywords: state => state.userData.package,
        getIsPackageOver5000: state => state.userData.package > 5000,
        getDailyAdded: state => state.dailyAdded,
        getIsFreePlan: state => state.isFreePlan,
        getKeywordsExceeded: state => state.used > state.userData.package,
        getIsAuthUser: state => state.isAuthUser,
        getEmailNotificationSettings: state => state.notificationSettings,
        getIsRestrictedAccessUser: state => state.isRestrictedAccessUser,
    },
    actions: {
        async fetchUsed({ dispatch, commit }) {
            try {
                commit(SET_USED_IS_FETCHING, true);
                const res = await usersApi.fetchUsed();

                dispatch('setUsed', res.data.data.used);
                dispatch('setDailyAdded', res.data.data.daily_added);
            } catch (e) {
                //
            } finally {
                commit(SET_USED_IS_FETCHING, false);
            }
        },
        async fetchUsedRefreshes({ dispatch }) {
            try {
                const res = await usersApi.fetchUsedRefreshes();
                dispatch('setUsedRefreshes', res.data.data.refreshed);
            } catch (e) {
                //
            }
        },
        async fetchSettings({ commit, dispatch, getters }) {
            commit(SET_SETTINGS_IS_FETCHING, true);

            try {
                const res = await usersApi.fetchSettings();
                const { data } = res;
                //Set pagination type for metrics for users
                const overviewUsers = process.env.PAGINATED_WITH_API_OVERVIEW_USERS || '';
                const paginateInFrontEnd = overviewUsers.split(',').includes(data.data.id);
                dispatch('setOverviewPaginateWithAPI', !paginateInFrontEnd);

                commit(SET_IS_AUTH_USER, true);
                dispatch('fetchCurrencies');
                dispatch('setTutorialMode', !data.data.attributes.onboarding);
                dispatch('setIsNotStripeUser', data.data.attributes.is_not_stripe_user);
                dispatch('setPaymentDetailsLoaded', true);
                await dispatch('saveUser', data);
                dispatch('saveSettings', data);

                const isFreePriceType = data.data.attributes?.subscription?.price_type === 'free';
                const redirect = !isFreePriceType && !getters.getExpirePostTrial;
                redirectIfExpired(getters.getIsExpired, redirect, toastr);

                commit(SET_SETTINGS_IS_FETCHING, false);
            } catch (error) {
                commit(SET_SETTINGS_IS_FETCHING, false);
            }
        },
        saveSettings({ commit, dispatch }, { included }) {
            const settings = included.find(include => include.type === 'user_settings').attributes;
            commit(SET_KEYWORDS_TABLE_SETTINGS, initColumnsConfig(settings.hidecolumns.split(';')));
            dispatch('setDisplaySettings', { ...settings, ...settings.sorting });
            dispatch('updateKeywordSettings', settings);
            dispatch('setPdfSettings', settings);
            dispatch('setOtherSettings', settings);
            dispatch('setAlertSettings', settings);
            dispatch('setReportsSettings', settings.email_reports);
            dispatch('setCompanySettings', settings.viewkey_settings);
            commit(SET_USER_SETTINGS, {
                invoicedetails: settings.invoicedetails,
                timezone: settings.timezone,
                newsletter_subscription: settings.newsletter_subscription,
            });
        },
        async saveUser({ commit, dispatch }, { data }) {
            const attributes = {
                ...data.attributes,
                username: data.id,
            };

            EventBus.emit(Events.USER_UPDATE, attributes);
            dispatch('setAccountSettings', attributes)
            dispatch('setBillingDetails', attributes.billing_details)
            dispatch('setUserData', attributes)
            await commit(SET_CURRENT_PLAN, attributes);
            dispatch('setSubscriptionData', attributes.subscription);
            dispatch('setPermissionsData', attributes.subscription.permissions);
            dispatch('setIsFreePlan', attributes.subscription.is_free_plan);
            commit(SET_SETTINGS_IS_FETCHING, false);
            // TODO: get back to this once billing will be available
            // if (typeof attributes.default_card === 'undefined') {
            //     throw new Error(i18n.t('billing-plan-fetch-error-msg'));
            // }

            commit(SET_DEFAULT_CARD, attributes.default_card);
            if(attributes.subscription.price_type === 'negotiated' && process.env.MIX_SENTRY_DSN) {
                try {
                    replay.start();
                } catch (error) {
                    console.error('Sentry Replay Error', error)
                }
            }
        },
        async updateUserAccountViewKey({ getters, dispatch }, payload) {
            const generatedData = {};
            const dataParams = {};
            const parsedProjectColumnSettings = {}
            Object.keys(getters.getReportsSettings).forEach(el => generatedData[el] = getters.getReportsSettings[el]);
            Object.keys(payload.projectColumnSettings).forEach(el => {
                parsedProjectColumnSettings[el] = payload.projectColumnSettings[el] ? 'show' : 'hide'
            });
            dataParams.whitelabelSettings = {
                ...payload.whitelabelSettings,
                ...generatedData,
            };
            dataParams.projectColumnSettings = {
                ...parsedProjectColumnSettings
            };
            if (dataParams.whitelabelSettings.company_url && !PROTOCOL_REGEXP.test(dataParams.whitelabelSettings.company_url)) {
                dataParams.whitelabelSettings.company_url = `http://${dataParams.whitelabelSettings.company_url}`
            }
            try {
                await usersApi.updateUserCompanySettings(dataParams);

                EventBus.emit(Events.UPDATE_ACCOUNT_WIDE_BRANDING, dataParams);
                toastr.s(i18n.t('saved-changes-msg'));
                dispatch('fetchSettings');
            } catch (error) {
                console.log(error, 'error')
            }
        },

        async updateEmailSettings({ getters, dispatch }, payload) {
            const whitelabelSettings = { ...getters.getCompanySettings, ...payload };
            try {
                await usersApi.updateUserCompanySettings({ whitelabelSettings });
                toastr.s(i18n.t('saved-changes-msg'));
                dispatch('fetchSettings');
            } catch (error) {
                console.log(error, 'error')
            }
        },

        async updateUserTableSettings({ getters, commit, dispatch }, { setting, value }) {
            const {
                tableAndOtherData,
                otherSettings,
                columnsSettings,
            } = prepareColumnsAndOtherSettings(getters, { setting, value });

            try {
                const data = { projectColumnSettings: tableAndOtherData };
                await usersApi.updateUserCompanySettings(data);

                EventBus.emit(Events.UPDATE_TABLE_SETTINGS, data);

                toastr.s(i18n.t('saved-changes-msg'));
                dispatch('updateOtherSettings', otherSettings);
                commit(SET_KEYWORDS_TABLE_SETTINGS, columnsSettings);
            } catch (e) {
                console.log(e, 'error')
            }
        },
        async updateUserDisplaySettings({ getters, dispatch }, { setting, value, showMsg }) {
            const {
                displayData,
                displaySettings,
            } = prepareDisplaySettings(getters, { setting, value });
            try {
                await usersApi.updateUserCompanySettings({ displaySettings: displayData });

                if (false !== showMsg) {
                    toastr.s(i18n.t('saved-changes-msg'));
                }

                dispatch('setDisplaySettings', displaySettings);
            } catch (e) {
                console.log(e, 'error')
            }
        },
        setUserData({ commit, dispatch, getters }, payload) {
            commit(SET_IS_RESTRICTED_ACCESS_USER, payload.user_is_restricted || false);
            redirectIfRestricted(payload.user_is_restricted);

            cookieSetters.setIsRestricted(payload.user_is_restricted || false);

            cookieSetters.setExpireAt(payload.subscription.account_expire_at);
            cookieSetters.setOnboarding(payload.onboarding);
            dispatch('setTutorialMode', !payload.onboarding);
            const userData = getters.getUserData;
            const userNewData = {
                ...userData,
            }
            const used = payload.used;
            const refreshed = payload.refreshes ? payload.refreshes.used : 0;
            const dailyAdded = payload.daily_added;

            dispatch('setUsed', used);
            dispatch('setUsedRefreshes', refreshed);
            dispatch('setDailyAdded', dailyAdded);

            userNewData.email = payload.username;
            userNewData.accessLevel = payload.access_level;
            userNewData.gscConnected = payload.gsc_connected;
            userNewData.createdAt = payload.created_at;
            userNewData.package = payload.package;
            userNewData.lastLoginAt = payload.last_login_at;
            userNewData.refreshRemaining = payload.refreshes ? payload.refreshes.remaining : 0;
            userNewData.refreshLimit = payload.refreshes ? payload.refreshes.limit : 0;
            userNewData.sharedCountApi = payload.sharedcountapi;
            userNewData.createdAtTs = payload.created_at_ts;
            userNewData.intercomHash = payload.intercom_hash;
            userNewData.intercomUserId = payload.intercom_user_id;
            userNewData.userId = payload.user_id;
            userNewData.addonMasterEmail = payload.addon_master_email;
            userNewData.addonMasterDisabled = payload.addon_master_disabled;
            userNewData.addonRestricted = payload.addon_restricted;
            userNewData.disabled = payload.disabled;
            userNewData.serpAndRankingHistoryLimitDate = payload.serp_and_ranking_history_limit_date;
            userNewData.trialIsUsed = payload.trial_is_used;
            userNewData.used = payload.used;
            userNewData.is_free_plan = payload.is_free_plan;
            localStorage.setItem('totalKeywords', payload.used);
            commit(SET_USER_DATA, userNewData);
            cookieSetters.setApiKey(payload.api_token);
            cookieSetters.setShowAll(payload.showall);
            cookieSetters.setUserProfile({
                email: payload.username,
                name: { first: null, last: null },
            });
        },
        setUsed({ commit }, payload) {
            commit(SET_USED, payload);
        },
        setUsedRefreshes({ commit }, payload) {
            commit(SET_USED_REFRESHES, payload);
        },
        setDailyAdded({ commit }, payload) {
            commit(SET_DAILY_ADDED, payload);
        },
        setSubscriptionData({ commit, getters }, payload = {}) {
            const subscriptionData = getters.getSubscriptionData;
            const newSubscriptionData = { ...subscriptionData };
            Object.keys(subscriptionData)
                .forEach(key => newSubscriptionData[key] = payload[key])
            commit(SET_SUBSCRIPTION_DATA, newSubscriptionData);
        },
        setPermissionsData({ commit, getters }, payload = {}) {
            const permissionsData = getters.getPermissionsData;
            const newPermissionsData = { ...permissionsData };
            Object.keys(permissionsData)
                .forEach(key => newPermissionsData[key] = payload[key]);
            commit(SET_PERMISSIONS_DATA, newPermissionsData);
            commit(SET_PERMISSIONS_DATA_LOADED, true);
        },
        setSettings({ commit, getters }, payload = {}) {
            const settings = getters.getUserSettings;
            const newData = {
                ...settings,
            }
            Object.keys(payload).forEach(key => newData[key] = payload[key])
            commit(SET_USER_SETTINGS, newData);
        },
        setProjectsSearch({ commit, getters }, payload = '') {
            const userSettings = getters.getUserSettings;
            commit(SET_PROJECTS_SEARCH, { ...userSettings, projectsSearch: payload });
        },
        setIsFreePlan({ commit }, payload = true) {
            commit(SET_IS_FREE_PLAN, payload);
        },
        setIsNotStripeUser({ commit }, payload) {
            commit(SET_IS_NOT_STRIPE_USER, payload);
        },
        setPaymentDetailsLoaded({ commit }, payload) {
            commit(SET_PAYMENT_DETAILS_LOADED, payload);
        },
        setIsAuthUser({ commit }, payload) {
            commit(SET_IS_AUTH_USER, payload);
        },
        async updateEmailNotificationSettings({ getters }, newSettings) {
            try {
                const resp = await userApi.saveEmailNotificationSettings({ data: { ...newSettings, isFetched: undefined } });
                const {performance_pulse_email_enabled, portfolio_snapshot_email_enabled, project_list} = resp.data.data;

                commit(SET_EMAIL_NOTIFICATION_SETTINGS_DATA, { performance_pulse_email_enabled, portfolio_snapshot_email_enabled, project_list, isFetched: true });
            } catch (e) {
                return e;
            }
        },
        async fetchEmailNotificationSettings({ commit }) {
            try {
                const resp = await userApi.fetchEmailNotificationSettings();
                if(resp.data) {
                    const { performance_pulse_email_enabled, portfolio_snapshot_email_enabled, project_list } = resp.data;

                    commit(SET_EMAIL_NOTIFICATION_SETTINGS_DATA, { performance_pulse_email_enabled, portfolio_snapshot_email_enabled, project_list, isFetched: true });
                }
            } catch (e) {
                return e;
            }
        },
    },
};

export default settings;
